import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';

// Components
import ReactTable from '@components/ReactTable';

import POSPage from '@components/POSPage'
import paginator from '@utils/paginator'

import Custom from './Custom'

import { EventBus as eventBus } from "@components/EventBus";

class Page extends Component {
    constructor(props) {
        super(props);

        this.state = {
            table: {
                columns: [
                    {
                      Header: 'Produto',
                      accessor: 'idproduct',
                    },
                    {
                      Header: 'Designação',
                      accessor: 'name',
                    },
                    {
                      Header: 'Quantidade',
                      accessor: 'qtd',
                    },
                    {
                        Header: 'Contagem',
                        id: 'counter',
                        accessor: d => (
                            <Custom key={d.idproduct} id={d.idproduct} setCustomValue={this.setCustomQuantity} customValue={d.counter} />
                        ),
                    },
                    {
                      Header: 'Diferença',
                      id: 'diff',
                      accessor: d => d.qtd !==  '-' && d.counter !== '' ? d.counter - d.qtd : '-',
                    },
                ],
                rows: [],
                loading: true,
            },
            summary: {
                category: { name: '' },
            },
            pagination: {
                left: {
                    first: {
                        click: () => this.setState({ page: 1 }),
                        show: true,
                    },
                    previous: {
                        click: () => this.setState({ page: this.state.page - 1 }),
                        show: true,
                    }
                },
                right: {
                    next: {
                        click: () => this.setState({ page: this.state.page + 1 }),
                        show: true,
                    },
                    last: {
                        click: () => this.setState({ page: this.state.lastPage }),
                        show: true,
                    }
                }
            },
            items: [],
            page: 1,
            lastPage: 0,
            pageSize: 24,
            loaded: false,
            filters: [{
                label: 'Nome',
                field: 'name',
                value: '',
                placeholder: 'Pesquise pelo Nome',
                handleChange: (e) => this.handleChangeFilter(e),
            },{
                label: 'ID Produto',
                field: 'idproduct',
                value: '',
                placeholder: 'Pesquise pelo ID do produto',
                handleChange: (e) => this.handleChangeFilter(e),
            },{
                label: 'Código de Barras',
                field: 'barcode',
                value: '',
                placeholder: 'Pesquise pelo Código de Barras',
                handleChange: (e) => this.handleChangeFilter(e),
            }],
        };

        this.loadFunction = this.loadFunction.bind(this);
        this.setCustomQuantity = this.setCustomQuantity.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
    }

    setCustomQuantity (quantity, id) {
        const newItems = this.state.table.rows.map((item) => ({
            ...item,
            counter: item.idproduct === id ? parseInt(quantity, 10) : item.counter,
        }));
        eventBus.$emit("SET/steps/form", { values: newItems });
        this.setState({ table: {
            ...this.state.table,
            rows: newItems
        } });
    }

    loadFunction() {
        if (!this.state.loaded) {
            const { form } = this.props;
            axios.get(`https://eposgestofinal.pt/api/products/posto/getAll.php?familia=${form.category.idcategoria}&idempresa=${this.props.cookies.get('member_idempresa')}&idposto=${form.warehouse.posto}`)
            .then(response => {
                const values = response.data !== 'NOK' ? response.data : [];

                const enrichedCustomers = values[form.warehouse.posto].items.map((value) => {
                    const specificValue = this.props.form.values.find((item) => value.idproduct === item.idproduct && item.counter > 0);
                    return {
                        ...value,
                        qtd: value.stock || '-',
                        counter: (specificValue || {}).counter || '',
                        barcode: value.barcodes.map(({ barcode }) => barcode).join(),
                    };
                });

                this.setState({ table: {
                    ...this.state.table,
                    rows: enrichedCustomers,
                    loading: false,
                }, loaded: true });
            })
            .catch(error => {
                NotificationManager.error(error.message, 'Produtos');
            });
        }
    }

    handleChangeFilter(e) {
        this.setState({ filters: this.state.filters.map((filter) => {
            return {
                ...filter,
                value: e.target.name === filter.field ? e.target.value : filter.value,
            }
        }) });
    }

    handleChange(e, callback) {
        let value = e.target.value;
        let name = e.target.name;
        
        this.setState({
            [name]: value,
        });
    }

    render () {
        const { items, pagination, page, pageSize, table, filters } = this.state;
        
        const pageInfo = paginator(items, page, pageSize);

        const realPagination = {
            left: pageInfo.pre_page ? pagination.left : { first: {}, previous: {} },
            right: pageInfo.next_page ? pagination.right : { next: {}, last: {} },
        }

        const filteredItems = table.rows.filter((item) => {
            return filters
                .every((filter) =>
                    filter.value
                    ? filter.value.split(' ')
                        .every((filterValue) => 
                            (item[filter.field] || '').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
                                .includes(filterValue.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
                        )
                    : true
                );
        });

        return (
            <POSPage
                pagination={ realPagination }
                filters={ filters }
                loadFunction={ this.loadFunction }
            >
                <ReactTable
                    columns={ table.columns }
                    sortable={ true }
                    loading={ table.loading }
                    data={ filteredItems }
                    defaultPageSize={ 20 }
                />
            </POSPage>
        );
    }
}

export default withCookies(Page);